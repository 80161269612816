import PropTypes from "prop-types";
import get from "lodash.get";
import kebabcase from "lodash.kebabcase";
import { fetchProps } from "../_utilities/data";
import { getLink } from "../_utilities/helpers";
import { isHTMLFragment } from "./CustomHTML.helpers";

const reconcileFontStyle = ({ size, fontStyle }) =>
  size === "Tiny" ? "Light" : fontStyle;

const reconcileFontFamily = ({ size, fontFamily }) =>
  size === "Tiny" ? "Default" : fontFamily;

const OPINION_RE = /^(opini(o|ó)n)$/i;

export const getHeadline = ({
  content = {},
  overrides = {},
  isAdmin = false
}) => {
  const {
    headline,
    headlineHide: hide = false,
    headlineSize: size = "Small",
    headlineStyle: fontStyle = "Bold",
    headlineFontFamily: fontFamily = "Default",
    headlinePrependBullet: prependBullet = false,
    headlinePrefixShow: prefixShow = false,
    deckShow = false
  } = overrides;

  let { headlineAlignment: alignment = "left" } = overrides;
  alignment =
    alignment === "inherit" && overrides.textAlignment
      ? overrides.textAlignment
      : alignment;

  // NOTE: hide is true
  if (hide) return undefined;

  // NOTE: getLink encapsulates logic for harvesting url, so use it.
  const { link = {} } = getLink({ content, overrides });
  const { url } = link;

  const textKeys = ["headlines.basic", "headlineText", "headline"];

  let prefix = prefixShow ? get(content, "label.transparency.text") : undefined;
  if (/^News$/i.test(prefix)) prefix = undefined;
  const isOpinionPrefix = prefix ? OPINION_RE.test(prefix) : undefined;

  let { text = headline, deck } = fetchProps({
    data: content,
    keys: {
      text: textKeys,
      deck: ["subheadlines.basic", "deck"]
    },
    overrides
  });

  const textPlaceholder = "Write headline here";
  const deckPlaceholder = "Write deck here";
  if (!isAdmin && text === textPlaceholder) {
    text = undefined;
  } else if (isAdmin || (text && text !== textPlaceholder)) {
    text = text || textPlaceholder;
  }

  if (!isAdmin && deck === deckPlaceholder) {
    deck = undefined;
  } else if (isAdmin || (deck && deck !== deckPlaceholder)) {
    deck = deck || deckPlaceholder;
  }

  if (!text) return undefined;

  return {
    isAdmin,
    isOpinionPrefix,
    prefix,
    text,
    deck: deckShow && deck ? deck : undefined,
    url,
    size,
    fontStyle: reconcileFontStyle({ size, fontStyle, fontFamily }),
    fontFamily: reconcileFontFamily({ size, fontStyle, fontFamily }),
    alignment,
    type: prependBullet ? "bullet" : "normal",
    // START: for toolbar only
    hide: !text ? true : hide,
    urlOverride: overrides.blurbUrl
  };
};

getHeadline.propTypes = {
  content: PropTypes.object,
  overrides: PropTypes.object,
  isAdmin: PropTypes.bool
};

export const groomHeadlineForJsonApp = ({ headline }) => {
  if (!headline || headline.hide) return undefined;
  delete headline.isAdmin;
  delete headline.hide;
  delete headline.url;
  delete headline.urlOverride;

  if (!headline?.deck) {
    delete headline.deck;
  }

  if (headline.type === "normal") {
    delete headline.type;
  }

  if (headline.size) {
    headline.size = headline.size.toLowerCase();

    // convert extra-large and extra-small to x-large and x-small
    if (headline.size.includes("extra")) {
      headline.size = headline.size.replace("extra", "x");
    }

    headline.size = kebabcase(headline.size);
  }

  if (headline.fontStyle) {
    headline.fontStyle = kebabcase(headline.fontStyle);
  }

  // NOTE: To apps, particular styles get applied to content from particular sections
  // So, the value "style" refers to the style section
  if (/Ultra/i.test(headline.fontFamily)) {
    headline.style = "style";
  }
  delete headline.fontFamily;

  if (headline.alignment) {
    headline.alignment = kebabcase(headline.alignment);
  }

  if (isHTMLFragment(headline.text) || isHTMLFragment(headline.deck)) {
    headline.mime = "text/html";
  }

  return headline;
};

groomHeadlineForJsonApp.propTypes = {
  headline: PropTypes.object
};
