import { defaultMenuItemProps } from "../_utilities/helpers";

import { headlineSizes } from "~/components/features/fronts/utilities/common-front-properties";

/**
 * Menu options
 * 1. Link headline: string
 * 2. Hide headline: bool
 * 3. Headline size: select
 * 4. Headline style: bold / italic
 * 5. Headline alignment: left / center
 * 6. Prepend bullet: bool
 */

const getHeadlineToolbarItems = ({
  urlOverride,
  hide,
  size,
  fontStyle,
  alignment,
  type
}) => {
  const toolbar = [
    {
      type: "textInput",
      props: { iconName: "link", label: "Link override" },
      initialVals: { defaultValue: urlOverride },
      handlers: {
        onBlur: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            entity.props.customFields.blurbUrl = props.value;
          });
        }
      }
    },
    {
      type: "button",
      props: {
        iconName: "hide",
        label: "Hide headline"
      },
      initialVals: { selected: hide },
      handlers: {
        onClick: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            entity.props.customFields.headlineHide =
              !entity.props.customFields.headlineHide;
          });
        }
      }
    },
    {
      type: "select",
      props: {
        items: Object.entries(headlineSizes),
        labelIcon: "textType"
      },
      initialVals: { defaultValue: size },
      handlers: {
        onChange: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            entity.props.customFields.headlineSize = props.value;
            if (props.value === "Tiny") {
              entity.props.customFields.headlineStyle = "Regular";
            }
          });
        }
      }
    },
    {
      type: "button",
      props: {
        iconName: "bold",
        label: "Bold"
      },
      initialVals: {
        disabled: size === "Tiny",
        selected: fontStyle === "Bold"
      },
      handlers: {
        onClick: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            const style = entity.props.customFields.headlineStyle;
            entity.props.customFields.headlineStyle =
              style === "Bold" ? "Regular" : "Bold";
          });
        }
      }
    },
    {
      type: "button",
      props: {
        iconName: "italic",
        label: "Italic"
      },
      initialVals: {
        disabled: size === "Tiny",
        selected: fontStyle === "Italic"
      },
      handlers: {
        onClick: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            const style = entity.props.customFields.headlineStyle;
            entity.props.customFields.headlineStyle =
              style === "Italic" ? "Regular" : "Italic";
          });
        }
      }
    },
    {
      type: "button",
      props: {
        iconName: "leftAlign",
        label: "Align left"
      },
      initialVals: { selected: alignment === "left" },
      handlers: {
        onClick: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            const textAlign = entity.props.customFields.headlineAlignment;
            entity.props.customFields.headlineAlignment =
              textAlign === "left" ? "inherit" : "left";
          });
        }
      }
    },
    {
      type: "button",
      props: {
        iconName: "centerAlign",
        label: "Center"
      },
      initialVals: {
        selected: alignment === "center"
      },
      handlers: {
        onClick: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            const textAlign = entity.props.customFields.headlineAlignment;
            entity.props.customFields.headlineAlignment =
              textAlign === "center" ? "inherit" : "center";
          });
        }
      }
    },
    {
      type: "button",
      props: {
        iconName: "bullet",
        label: "Prepend bullet"
      },
      initialVals: {
        selected: type === "bullet"
      },
      handlers: {
        onClick: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            const prependBullet =
              entity.props.customFields.headlinePrependBullet;
            entity.props.customFields.headlinePrependBullet = !prependBullet;
          });
        }
      }
    }
  ];

  return toolbar;
};

export default getHeadlineToolbarItems;
