import PropTypes from "prop-types";

/* Utilities */
import { isHTMLFragment } from "./CustomHTML.helpers";

export const getFootnote = ({ overrides = {}, isAdmin }) => {
  const {
    footnoteHide = true,
    footnoteText,
    footnoteUrl,
    footnoteAlignment = "right",
    textAlignment = "right"
  } = overrides;

  if (footnoteHide) return undefined;

  let text = footnoteText;
  const placeholderText = "Write footnote here";
  if (isAdmin) {
    text = text || placeholderText;
  } else if (!isAdmin && text === placeholderText) {
    text = "";
  }

  if (!text) return undefined;

  const alignment =
    !footnoteAlignment || footnoteAlignment === "inherit"
      ? textAlignment
      : footnoteAlignment;

  let mime;
  if (isHTMLFragment(text)) {
    mime = "text/html";
  }

  return {
    isAdmin,
    alignment,
    text,
    link: {
      url: footnoteUrl,
      type: "unknown"
    },
    mime
  };
};

getFootnote.propTypes = {
  content: PropTypes.object,
  overrides: PropTypes.object
};

export const groomFootnoteForJsonApp = ({ footnote }) => {
  if (!footnote) return undefined;

  if (!footnote?.link?.url) delete footnote.link;

  return footnote;
};
