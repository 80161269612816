import PropTypes from "prop-types";

/* Utilities */
import { fetchProps, getLoopControl } from "../_utilities/data";
import { getLink } from "../_utilities/helpers";
import { isHTMLFragment } from "./CustomHTML.helpers";

export const getMultiBlurb = ({ content, overrides = {}, isAdmin }) => {
  // NOTE: Goal is to produce this because it conforms to the current jsonApp structure
  /*
    {
      items: [
        {
          text: "string"
        }
      ],
      link: {
        type: "string:article|video|gallery|web|etc.",
        url: "string:url",
        last_modified: "formatted-string:YYYY-mm-dd'T'hh:mm:ss.S'Z'",
      },
      // SOMETIMES PRESENT
      offline_link: {
        type: "string:article|video|gallery|web|etc.",
        url: "string:url",
        last_modified: "formatted-string:YYYY-mm-dd'T'hh:mm:ss.S'Z'",
      }
    }
  */

  const {
    blurbHide = false,
    blurbNumExtrasToShow = 0,
    blurbOrderOfExtras = ""
  } = overrides;

  // RETURN undefined: No blurbs are due to be shown.
  if (blurbHide) return undefined;

  // NOTE: Assemble list of blurbs
  const blurbs = [];
  const link = getLink({ content, overrides }).link;
  const offlineLink = getLink({ content, overrides }).offlineLink;

  // NOTE: Start with extra blurbs for ease of counting
  if (blurbNumExtrasToShow > 0) {
    const loopControl = getLoopControl({
      n: blurbNumExtrasToShow,
      order: blurbOrderOfExtras.split(",")
    });

    loopControl.forEach((v) => {
      const contentKey = `feature_summary_${v}_text`;
      const overrideKey = `descriptionText${v}`;
      const defaultValue = `Write blurb ${v} here.`;
      const blurb = {};

      const extraBlurb = fetchProps({
        data: content,
        keys: {
          blurb: [contentKey, overrideKey]
        },
        overrides
      });

      if (isAdmin || (extraBlurb.blurb && extraBlurb.blurb !== defaultValue)) {
        blurb.text = extraBlurb.blurb || defaultValue;
        blurb.path = overrideKey;
        blurbs.push(blurb);
      }
    });
  }

  // START: Get data for main blurb
  const keys = {
    text: ["description.basic", "descriptionText"]
  };

  const { text } = fetchProps({
    data: content,
    keys,
    overrides
  });
  // END: Get data for main blurb

  // START: Main blurb
  if (!blurbHide) {
    const blurb = {};
    const defaultValue = `Write blurb here.`;

    if (isAdmin || (text && text !== defaultValue)) {
      blurb.text = text || defaultValue;
      blurb.path = keys.text[1];
      blurbs.unshift(blurb);
    }
  }
  // END: Main blurb

  if (!blurbs || blurbs.length < 1) return undefined;

  return {
    items: blurbs,
    link,
    offlineLink,
    // NOTE: for toolbar only
    numExtrasToShow: blurbNumExtrasToShow
  };
};

getMultiBlurb.propTypes = {
  content: PropTypes.object,
  overrides: PropTypes.object,
  isAdmin: PropTypes.bool
};

export const groomMultiBlurbForJsonApp = ({ blurbs }) => {
  if (!blurbs || blurbs.hide) return undefined;
  (blurbs?.items || []).forEach((blurb) => {
    delete blurb.path;
    if (isHTMLFragment(blurb.text)) {
      blurb.mime = "text/html";
    }
  });

  delete blurbs.link;
  delete blurbs.numExtrasToShow;

  return blurbs;
};

groomMultiBlurbForJsonApp.propTypes = {
  blurbs: PropTypes.object
};
