import { theme } from "@washingtonpost/wpds-ui-kit";

export const convertArtSize = (baseValues) => {
  const artSizeConversion = {
    "XX-Large": "xxl",
    "X-Large": "xl",
    Large: "lg",
    Medium: "md",
    Small: "sm",
    "X-Small": "xs",
    Tiny: "tiny",
    Mini: "mini"
  };

  return baseValues &&
    baseValues.artSize &&
    artSizeConversion[baseValues.artSize]
    ? artSizeConversion[baseValues.artSize]
    : "fullWidth";
};

/*
 * Data about how to render homepage sizes
 * [font-family, font-size, font-size @sm. line-height, text-transform]
 * 3-digit font-sizes are tokens from theme.fontSizes
 */
const sizes = {
  blurb: ["subhead", 100, 100, 1.3],
  deck: ["subhead", 150, 100, 1.2],
  "related-links": ["subhead", "087", "100", 1.2],
  tiny: ["subhead", "0.9375rem", 100, 1.3],
  "extra-small": ["headline", 112, 125, 110],
  small: ["headline", 125, 125, 110],
  medium: ["headline", 150, 125, 110],
  standard: ["headline", 162, 162, 110],
  large: ["headline", 200, 162, 110],
  "extra-large": ["headline", 250, 162, 110],
  huge: ["headline", 350, 250, 110],
  massive: ["headline", 450, 250, 110],
  colossal: ["headline", "5.875rem", 250, 110],
  "colossal-all-caps": ["headline", "5.875rem", 350, 110, "uppercase"],
  jumbo: ["headline", "7.1875rem", 250, 110],
  "jumbo-all-caps": ["headline", "7.1875rem", 350, 110, "uppercase"],
  gargantuan: ["headline", "8.8125rem", 250],
  "gargantuan-all-caps": ["headline", "8.8125rem", 350, 110, "uppercase"]
};

/*
 * Generates the css object needed for headline sizes. It takes
 * into account
 *   1. font-family
 *   2. font-size
 *   3. font-size at the @sm breakpoint (optional)
 *   4. line-height
 *   5. text-transform, to handle all-caps
 *
 * @param {string} size -- One if the keys in sizes otherwise
 *
 * @return {object} -- css object suitable for passing to stitches
 */
export const getStylesFromSize = (size) => {
  if (Object.keys(sizes).includes(size)) {
    const [family, desktop = "1rem", atSm, lineHeight, textTransform] =
      sizes[size];
    // 3-digit font-sizes will be treated as tokens from theme.fontSizes
    // otherwise use them as-is
    return {
      fontFamily: theme.fonts[family],
      fontSize: /\d{3}$/.test(desktop) ? theme.fontSizes[desktop] : desktop,
      ...(textTransform ? { textTransform } : {}),
      ...(lineHeight
        ? {
            lineHeight: /\d{3}$/.test(lineHeight)
              ? theme.lineHeights[lineHeight]
              : lineHeight
          }
        : {}),
      ...(atSm && atSm !== desktop
        ? {
            "@sm": {
              fontSize: /\d{3}$/.test(atSm) ? theme.fontSizes[atSm] : atSm
            }
          }
        : {})
    };
  }
  return {};
};

// export const touchDeviceSel = "@media (pointer: coarse)";
export const touchDeviceSel = "@media (hover: none)";

export const carouselFooterCss = {
  display: "none",
  [touchDeviceSel]: {
    display: "flex"
  }
};

export const carouselButtonCss = {
  "&:disabled": {
    opacity: 0,
    cursor: "default"
  },
  insetBlockStart: "50%",
  position: "absolute",
  transform: "translate(-50%, -50%)",
  padding: "6px", // to keep the buttons within the HP grid
  zIndex: 2,
  "@sm": {
    display: "unset",
    [touchDeviceSel]: {
      display: "none"
    }
  }
};
