import PropTypes from "prop-types";
import get from "lodash.get";
import kebabcase from "lodash.kebabcase";

import { getMedia } from "./LiveGraphic.helpers";

export const getElection = ({
  // content = {},
  overrides = {},
  electionData,
  isAdmin = false
}) => {
  if (!electionData) return undefined;
  if (!(electionData.component && electionData.id)) {
    return undefined;
  }

  // NOTE: For jsonapp except useScreenshot
  const { liveGraphicsUseScreenshot = false } = overrides;

  const useScreenshot =
    get(electionData, "useScreenshot", false) || liveGraphicsUseScreenshot;
  const updateClientSide = !get(electionData, "skipResultsFetch", false);
  const showErrorMessage = isAdmin;

  // NOTE: Structure liveImages for media object
  const media = getMedia(electionData, overrides, "media.link");

  return {
    ...electionData,
    media,
    useScreenshot,
    updateClientSide,
    showErrorMessage
  };
};

getElection.propTypes = {
  // content: PropTypes.object,
  overrides: PropTypes.object,
  electionData: PropTypes.shape({
    component: PropTypes.string.isRequired
  })
  // isAdmin: PropTypes.bool,
};

export const groomElectionForJsonApp = ({ election }) => {
  if (!election) return {};

  // NOTE: if web_embed is not active, don't use it
  if (election?.web_embed?.active === false) delete election.web_embed;
  else delete election?.web_embed?.active;

  const { media, web_embed } = election;

  if (!media && !web_embed) return {};

  if (media?.artPosition) media.artPosition = kebabcase(media.artPosition);
  if (media?.artWidth) media.artWidth = kebabcase(media.artWidth);

  const dynamicReplacement = web_embed ? "web_embed" : undefined;

  return {
    media: {
      ...(dynamicReplacement
        ? {
            [dynamicReplacement]: web_embed,
            dynamicReplacement
          }
        : {}),
      ...media
    }
  };
};

groomElectionForJsonApp.propTypes = {
  election: PropTypes.object
};
