import PropTypes from "prop-types";

import get from "lodash.get";

export const getCount = ({ content, overrides = {} }) => {
  // NOTE: Goal is to produce this because it conforms to the current jsonApp structure
  /*
    {
      count: int,
      size: string:large|medium
    }
  */
  // TODO: make countShow a custom field
  const { countShow = false } = overrides;

  // RETURN undefined: No countShow is false
  if (!countShow) return undefined;

  // TODO: make countOverride (countToShow better?) a custom field
  let count =
    get(overrides, "countOverride") ||
    get(content, "fusion_additions.count_info.count", 0);

  count = Math.round(Number.parseInt(count, 10) || 0);

  // TODO: if necessary, take offsets into account

  // TODO: make countSize custom field
  // NOTE: for now, based on count. Could be based on headline size, too.
  let size = get(overrides, "countSize");
  if (!/^(large|medium)$/i.test(size)) {
    size = count === 1 ? "large" : "medium";
  }

  // NOTE: Coerce count to string
  return count < 1 ? undefined : { count: `${count}`, size };
};

getCount.propTypes = {
  content: PropTypes.object,
  overrides: PropTypes.object
};
