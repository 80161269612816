import get from "lodash.get";

import { defaultMenuItemProps } from "../_utilities/helpers";

/**
 * Menu options
 * 1. Related link url: string
 * 2. Hide/show related links: bool
 * 3. Toggle related links label: bool
 * 4. Toggle side-by-side style: bool
 * 5. Number of links: number
 * 6. Related links order: string (e.g. '3,2,1')
 */

const getRelatedLinksToolbarItems = ({
  items,
  relatedLinksOrder,
  compoundLabel,
  arrangement,
  item,
  useDesktopOrdering
}) => {
  const relatedLinksInfo = useDesktopOrdering
    ? {
        key: "relatedLinksNum",
        label: "Number of links",
        coerceFn: (v) => Number(v)
      }
    : {
        key: "relatedLinksNumMobileOnly",
        label: "Num on mobile",
        coerceFn: (v) => String(v)
      };

  const toolbar = [
    {
      type: "textInput",
      props: { iconName: "link", label: "Link" },
      initialVals: { defaultValue: item.url },
      handlers: {
        onBlur: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            entity.props.customFields[get(item, "linkKey")] = props.value;
          });
        }
      }
    },
    {
      type: "button",
      props: {
        iconName: "hide"
      },
      initialVals: { selected: false },
      handlers: {
        onClick: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            entity.props.customFields[relatedLinksInfo.key] = 0;
          });
        }
      }
    },
    {
      type: "button",
      props: { iconName: "textField" },
      initialVals: {
        selected: !!get(compoundLabel, "label.text")
      },
      handlers: {
        onClick: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            const hasLabel = !!entity.props.customFields.relatedLinksLabel;
            entity.props.customFields.relatedLinksLabel = hasLabel
              ? ""
              : "LABEL";
          });
        }
      }
    },
    {
      type: "button",
      props: {
        iconName: "sideBySide"
      },
      initialVals: {
        selected: arrangement === "side-by-side"
      },
      handlers: {
        onClick: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            const style = entity.props.customFields.relatedLinksStyle;
            entity.props.customFields.relatedLinksStyle =
              style === "Side-by-side" ? "normal" : "Side-by-side";
          });
        }
      }
    },
    {
      type: "numberSelect",
      props: {
        iconName: "addItems",
        label: relatedLinksInfo.label,
        items: Array(6)
          .fill("")
          .map((o, i) => String(i))
      },
      initialVals: {
        defaultValue: String(items.length)
      },
      handlers: {
        onChange: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            entity.props.customFields[relatedLinksInfo.key] =
              relatedLinksInfo.coerceFn(props.value);
          });
        }
      }
    },
    {
      type: "textInput",
      props: {
        iconName: "formatListNumbered",
        label: "Related Links Order"
      },
      initialVals: {
        defaultValue: relatedLinksOrder
      },
      handlers: {
        onBlur: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            entity.props.customFields.relatedLinksOrder = String(props.value);
          });
        }
      }
    }
  ];

  return toolbar;
};

export default getRelatedLinksToolbarItems;
