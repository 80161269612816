import PropTypes from "prop-types";

import get from "lodash.get";

export const getRecipe = ({ content, overrides = {} }) => {
  // NOTE: Goal is to produce this because it conforms to the current jsonApp structure
  /*
    {
      totalTime: int, // time in seconds
      courses: [
        { id: string: description: string }
      ],
      rating: {
        provenance: string:recipe|fact-checker,
        type: string:star|pinnocchio,
        value: num,
        count: num,
        max: num
      }
    }
  */

  const { recipeShow = false } = overrides;

  // RETURN undefined cuz recipeShow is false
  if (!recipeShow) return undefined;

  let { total_time: totalTime, courses } = get(
    content,
    "fusion_additions.recipe_info",
    {}
  );

  let rating = get(content, "fusion_additions.rating_info", undefined);
  rating = /recipe/.test(rating?.provenance) ? rating : undefined;
  // NOTE: Round to nearest tenth
  if (rating?.value) rating.value = Math.round(10 * rating.value) / 10;

  totalTime = !Number.isNaN(totalTime) && totalTime > 0 ? totalTime : undefined;
  courses = courses || undefined;

  // RETURN undefined cuz no totalTime and no courses
  if (!totalTime && !courses) return undefined;

  return { totalTime, courses, rating };
};

getRecipe.propTypes = {
  content: PropTypes.object,
  overrides: PropTypes.object
};
