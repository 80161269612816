import upperFirst from "lodash.upperfirst";
import { defaultMenuItemProps } from "../_utilities/helpers";

import {
  artPositions,
  aspectRatios,
  aspectRatiosMobileOnly,
  artSizes
} from "~/components/features/fronts/utilities/common-front-properties";

import { getArtSize } from "~/components/features/fronts/flex-feature/utilities/index";
import { generateAnglerfishEditorUrl } from "./Image.helpers";

/**
 * Menu items
 * 1. Alternate art: string
 * 2. Cover image for video or custom HTML: string
 * 3. Aspect ratio: select
 * 4. Hide image: bool
 * 5. Art position: select
 * 6. Art size: select
 * SLIDESHOW
 * 7. Toggle "Show slideshow": bool
 * 8. Slideshow urls: string
 */

const getImageToolbarItems = ({
  rawProps = {},
  useDesktopOrdering,
  ...rest
}) => {
  const {
    alternateArt,
    artAspectRatio,
    artPosition,
    coverArtUrl,
    artWidth,
    slideshowShow,
    slideshowUrls,
    url
  } = {
    ...rest,
    alternateArt:
      (useDesktopOrdering
        ? rawProps.alternateArt
        : rawProps.alternateArtMobileOnly) || rest.alternateArt,
    artAspectRatio:
      (useDesktopOrdering
        ? rawProps.artAspectRatio
        : rawProps.artAspectRatioMobileOnly) || rest.artAspectRatio
  };

  const isMobile = !useDesktopOrdering;
  const alternateArtInfo = useDesktopOrdering
    ? {
        key: "alternateArt",
        label: "Alternate art"
      }
    : {
        key: "alternateArtMobileOnly",
        label: "Alternate art on mobile"
      };
  const artAspectRatioInfo = useDesktopOrdering
    ? {
        key: "artAspectRatio",
        aspectRatios
      }
    : {
        key: "artAspectRatioMobileOnly",
        aspectRatios: aspectRatiosMobileOnly
      };

  const toolbar = [
    {
      type: "textInput",
      props: { iconName: "imageCompare", label: alternateArtInfo.label },
      initialVals: { defaultValue: alternateArt },
      handlers: {
        onBlur: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            entity.props.customFields[alternateArtInfo.key] = props.value;
          });
        }
      }
    },
    {
      type: "textInput",
      props: { iconName: "coverImage", label: "Cover image" },
      initialVals: { defaultValue: coverArtUrl },
      handlers: {
        onBlur: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            entity.props.customFields.coverArtUrl = props.value;
          });
        }
      }
    },
    {
      type: "button",
      props: {
        iconName: "crop",
        label: "Crop"
      },
      initialVals: { disabled: generateAnglerfishEditorUrl(url) === null },
      handlers: {
        onClick: () => {
          const anglerfishUrl = generateAnglerfishEditorUrl(url);
          window.open(anglerfishUrl, "_blank", "popup");
        }
      }
    },
    {
      type: "select",
      props: {
        items: Object.entries(artAspectRatioInfo.aspectRatios),
        labelIcon: "aspectRatio"
      },
      initialVals: { defaultValue: artAspectRatio },
      handlers: {
        onChange: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            entity.props.customFields[artAspectRatioInfo.key] = props.value;
          });
        }
      }
    },
    !isMobile && {
      type: "button",
      props: {
        iconName: "hide",
        label: "Hide art"
      },
      initialVals: { selected: false },
      handlers: {
        onClick: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            entity.props.customFields.artHide =
              !entity.props.customFields.artHide;
          });
        }
      }
    },
    !isMobile && {
      type: "select",
      props: {
        items: Object.entries(artPositions),
        labelIcon: "controlCamera"
      },
      initialVals: { defaultValue: upperFirst(artPosition).replace(/-/g, " ") },
      handlers: {
        onChange: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            entity.props.customFields.artPosition = props.value;
          });
        }
      }
    },
    !isMobile && {
      type: "select",
      props: {
        items: Object.entries(artSizes),
        labelIcon: "photoSizeSelect"
      },
      initialVals: { defaultValue: getArtSize(artWidth) },
      handlers: {
        onChange: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            entity.props.customFields.artSize = props.value;
          });
        }
      }
    },
    !isMobile && {
      type: "button",
      props: {
        iconName: "slideshow",
        label: slideshowShow ? "Hide slideshow" : "Show slideshow"
      },
      initialVals: { selected: slideshowShow },
      handlers: {
        onClick: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            entity.props.customFields.slideshowShow =
              !entity.props.customFields.slideshowShow;
          });
        }
      }
    },
    !isMobile && {
      type: "textArea",
      props: { iconName: "photoLibrary", label: "Slideshow URLs" },
      initialVals: { defaultValue: slideshowUrls },
      handlers: {
        onBlur: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            entity.props.customFields.slideshowUrls = props.value;
          });
        }
      }
    }
  ];

  return toolbar;
};

export default getImageToolbarItems;
