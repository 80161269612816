import PropTypes from "prop-types";
import get from "lodash.get";

import { getLink } from "../_utilities/helpers";
import { getCaption, NO_CAPTION } from "./Caption.helpers";
import { isHTMLFragment } from "./CustomHTML.helpers";
import {
  calculateAspectRatio,
  getAnglerfishImageId,
  getArtOverlay,
  getHiResInfo,
  groomArtOverlayForJsonApp,
  getImageLink,
  isImageUrl
} from "./Image.helpers";

// WARNING: Changing the value of MAX_SLIDES will cause a one-time
// violation of the rule-of-hooks
export const MAX_SLIDES = 10;
export const DEFAULT_SLIDE_DURATION = 7;
export const DEFAULT_TRANSITION_DURATION = 1.5;

export const getSlideshowUrls = ({ overrides }) => {
  const { artHide, slideshowShow = false, slideshowUrls = "" } = overrides;
  if (artHide || !slideshowShow || !slideshowUrls) return [];
  return slideshowUrls
    .split(/\s+/)
    .filter((url) => isImageUrl(url))
    .filter((_, i) => i < MAX_SLIDES);
};

export const getSlideshow = ({
  content,
  slideshowListOfData,
  artSlot,
  overrides,
  isAdmin
}) => {
  const {
    alternateArt,
    artAspectRatio,
    artHide,
    captionContent,
    coverArtUrl,
    disableLazyLoading,
    slideshowShow = false,
    slideshowUrls = "",
    slideshowSlideDuration = DEFAULT_SLIDE_DURATION,
    slideshowTransitionDuration = DEFAULT_TRANSITION_DURATION,
    slideshowMaxLoops = 100,
    slideshowAutoplay = true,
    slideshowDots = "touch",
    slideshowScalingStrategy = "aspect-fit",
    slideshowUseSameCaption = false
  } = overrides;

  if (artHide || !slideshowShow || !slideshowUrls) return undefined;

  let images = getSlideshowUrls({ overrides });

  if (!images.length) return undefined;

  // START: Handle use same caption
  let fixedIndex;
  let fixedUrl;
  let fixedId;
  if (slideshowUseSameCaption) {
    fixedIndex = 0;
    fixedUrl = images[fixedIndex];
    fixedId = getAnglerfishImageId(fixedUrl);
  }
  // END: Handle use same caption

  // START: Structure the images
  images = images.map((url, i) => {
    const imageData = get(
      slideshowListOfData,
      `[${fixedIndex !== undefined ? fixedIndex : i}]`,
      undefined
    );
    return {
      url,
      hiResInfo: getHiResInfo({ overrides, imageData, isAdmin }),
      caption: getCaption({
        content,
        imageData,
        artSlot: {
          url: fixedUrl || url,
          anglerfishId: fixedId || getAnglerfishImageId(url)
        },
        overrides,
        isAdmin
      })
    };
  });
  // END: Structure the images

  // START: Caption stuff
  let allowCaptions = captionContent && captionContent !== NO_CAPTION;
  const charsInLongestCaption = allowCaptions
    ? images
        .slice(0, slideshowUseSameCaption ? 1 : undefined)
        .reduce((acc, image) => {
          const caption = get(image, "caption.caption", "");
          return caption.length > acc ? caption.length : acc;
        }, 0)
    : 0;
  if (charsInLongestCaption === 0) allowCaptions = false;
  // END: Caption stuff

  // START: Get clean numbers
  let duration = Number.parseFloat(slideshowSlideDuration, 10);
  duration = Number.parseFloat(
    Number.isNaN(duration) ? DEFAULT_SLIDE_DURATION : duration,
    10
  );
  let fadeSpeed = Number.parseFloat(slideshowTransitionDuration, 10);
  fadeSpeed = Number.parseFloat(
    Number.isNaN(fadeSpeed) ? DEFAULT_TRANSITION_DURATION : fadeSpeed,
    10
  );
  fadeSpeed = fadeSpeed > duration ? duration : fadeSpeed;
  // END: Get clean numbers

  // START: link obj
  let link;
  const linkUrl = getImageLink({ content, overrides });
  const linkObj = getLink({ content, overrides });
  if (linkUrl !== linkObj?.link?.url) {
    link = {
      url: linkUrl,
      type: "unknown"
    };
  }
  // END: link obj

  // START: useHiRes / warning
  // NOTE: if any of the images are hi-res, the slideshow should be rendered hi-res
  const { useHiRes = false, warning } = images.reduce((acc, _) => {
    if (!acc.useHiRes) {
      acc.useHiRes = _.hiResInfo.useHiRes;
      acc.warning = _.hiResInfo.warning;
    }
    return acc;
  }, {});
  // END: get hi res info

  const maxLoops = Math.max(Math.min(slideshowMaxLoops, 100), 1);

  return {
    images: images.filter((_) => _?.url),
    aspectRatioString: artAspectRatio,
    artWidth: artSlot.artWidth,
    useHiRes,
    warning,
    allowCaptions,
    charsInLongestCaption,
    useSameCaption: slideshowUseSameCaption,
    overlay: getArtOverlay({ content, overrides }),
    linkUrl,
    duration,
    fadeSpeed,
    maxLoops,
    autoplay: slideshowAutoplay,
    dots: slideshowDots,
    scalingStrategy: slideshowScalingStrategy,
    lazyLoad: !disableLazyLoading,
    // START: needed for jsonapp
    link,
    // END: needed for jsonapp
    // START: needed for the admin toolbar
    alternateArt,
    artPosition: artSlot.artPosition,
    artAspectRatio,
    aspectRatio: calculateAspectRatio(artAspectRatio),
    coverArtUrl,
    slideshowShow,
    slideshowUrls,
    isAdmin
    // END: needed for the admin toolbar
  };
};

getSlideshow.propTypes = {
  content: PropTypes.object,
  slideshowListOfData: PropTypes.object,
  artSlot: PropTypes.object,
  overrides: PropTypes.object,
  isAdmin: PropTypes.bool
};

// TODO: Make these more than stubs
export const groomSlideshowForJsonApp = ({ slideshow }) => {
  if (!slideshow) return undefined;

  const dynamicReplacement = "slideshow";

  // NOTE: Enhancements
  slideshow.images.forEach((image) => {
    image.caption = image?.caption?.caption;
    if (isHTMLFragment(image.caption)) {
      image.mime = "text/html";
    }
    delete image.hiResInfo;
  });
  slideshow.overlay = groomArtOverlayForJsonApp({
    overlay: slideshow.overlay
  });

  // NOTE: Deletions
  delete slideshow.aspectRatioString;
  delete slideshow.artWidth;
  delete slideshow.useHiRes;
  delete slideshow.allowCaptions;
  delete slideshow.charsInLongestCaption;
  delete slideshow.useSameCaption;
  delete slideshow.linkUrl;
  delete slideshow.duration;
  delete slideshow.fadeSpeed;
  delete slideshow.maxLoops;
  delete slideshow.autoplay;
  delete slideshow.dots;
  delete slideshow.lazyLoad;
  delete slideshow.artAspectRatio;
  delete slideshow.artPosition;
  delete slideshow.alternateArt;
  delete slideshow.coverArtUrl;
  delete slideshow.slideshowShow;
  delete slideshow.slideshowUrls;
  delete slideshow.isAdmin;

  return {
    dynamicReplacement,
    [dynamicReplacement]: {
      ...slideshow
    }
  };
};

groomSlideshowForJsonApp.propTypes = {
  slideshow: PropTypes.object,
  media: PropTypes.object
};
