import get from "lodash.get";

import { artBreakpointSizes, artSizeTranslator } from "./Image.constants";
import { getImageLink } from "./Image.helpers";

/*
 * This is for live-graphic live-images
 *
 * @return -- Return the colspan of the live image that is closest
 *   (erring on the high side) to the colspan of the artSlot.
 */
export const getClosestColspan = (colspan, item) => {
  const liveImage = item.media.liveImage;
  const artWidth = item.media.artWidth;
  const artSlotColspan = get(
    artBreakpointSizes[colspan],
    `${artSizeTranslator[artWidth]}`,
    colspan
  );
  const keys = Object.keys(liveImage).map((v) => Number.parseInt(v, 10));
  return keys.reduce((a, b) => {
    const aDiff = Math.abs(a - artSlotColspan);
    const bDiff = Math.abs(b - artSlotColspan);
    if (aDiff === bDiff) {
      return a > b ? a : b;
    }
    return bDiff < aDiff ? b : a;
  });
};

// NOTE: Remove key if it is not a number
// The number should be a grid unit
export const groomLiveImages = (liveImages) => {
  return Object.keys(liveImages)
    .filter((v) => !Number.isNaN(Number.parseInt(v, 10)))
    .reduce((acc, v) => {
      acc[v] = liveImages[v];
      return acc;
    }, {});
};

/*
 * This creates the proper media object for live-graphic live-images.
 *
 * @param liveGraphicData obj -- liveGraphicData should be like electionData or olympicsData and have liveImages
 * @param overrides obj -- artSize and artPosition harvested from these.
 *    Also, needed for the call to getImageLink();
 * @param linkKey string -- This is the string in dot notation of where find the link url for the live-image.
 *    e.g. "media.link" for electionData
 *    e.g. "cta.link.url" for olympicsData
 *
 * @return -- Returns media object
 */
export const getMedia = (liveGraphicData, overrides, linkKey) => {
  const liveImages = groomLiveImages(get(liveGraphicData, "liveImages", {}));
  const link =
    !!linkKey &&
    (getImageLink({ content: {}, overrides }) ||
      get(liveGraphicData, linkKey, undefined));

  if (Object.keys(liveImages).length) {
    const { artPosition, artSize: artWidth } = overrides;
    return {
      // NOTE: This mediaType triggers manipulations when building the jsonapp output.
      mediaType: "live-image-by-grid-units",
      link: !link
        ? undefined
        : {
            url: link,
            type: "unknown"
          },
      artPosition,
      artWidth,
      liveImage: liveGraphicData.liveImages
    };
  }
  return undefined;
};
