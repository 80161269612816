import PropTypes from "prop-types";

import get from "lodash.get";

/* Utilities */
import { fetchProps } from "../_utilities/data";

/* Helpers */
import {
  getCompoundLabel,
  groomCompoundLabelForJsonApp
} from "./Label.helpers";

export const MIN = 2;
export const MAX = 8;
export const NUM_CAROUSEL = 10;

const getPollingInfo = (props) => {
  let { isLive = false, pollFrequency = 60 } = props;

  pollFrequency = /^\d+$/.test(pollFrequency) ? pollFrequency : 60;
  isLive = pollFrequency === 0 ? false : !!isLive;

  return { isLive, pollFrequency };
};

export const getLiveTicker = ({
  content,
  liveTickerData,
  overrides,
  isAdmin
}) => {
  // NOTE: Goal is to produce this because it conforms to the current jsonApp structure
  /*
    {
      url: "string:url",
      numToShow: int,
      numToShowCarousel: int,
      subtypes: [], // any of live-(outcome|race-call|reporter-insight|update)
      items: [
        // NOTE: This structure is determined by the content itself
        // And is not constructed here.
        {
          canonical_url: "string:url",
          headlines {
            basic: "string"
          },
          display_date: "formatted-string:YYYY-mm-dd'T'hh:mm:ss.S'Z'",
        }
        ...
      ],
      label: getCompoundLabel()
  }
  */

  const fetchedProps = fetchProps({
    data: liveTickerData || content,
    keys: {
      // Using dangerous.canonical on purpose b/c links_to_use.basic could get overwritten
      url: ["fusion_additions.links_to_use.dangerous.canonical"],
      items: ["fusion_additions.live_ticker.items"],
      isLive: ["fusion_additions.live_ticker.is_live"],
      pollFrequency: ["fusion_additions.live_ticker.poll_frequency"],
      // NOTE: This is the subtype of the story, not a content_element
      subtype: ["subtype"]
    },
    overrides
  });
  const { url, subtype } = fetchedProps;
  const { isLive, pollFrequency } = getPollingInfo(fetchedProps);
  let { items = [] } = fetchedProps; // Set a default array to items if it's not on fetchedProps.

  const {
    liveTickerHide = false,
    liveTickerNum = 3,
    liveTickerUpdatesShow = true,
    liveTickerOutcomesShow = true,
    liveTickerRaceCallsShow = true,
    liveTickerReporterInsightsShow = true,
    liveTickerTimestampShow = true
  } = overrides;

  const subtypes = [];
  if (liveTickerOutcomesShow) subtypes.push("live-outcome");
  if (liveTickerRaceCallsShow) subtypes.push("live-race-call");
  if (liveTickerReporterInsightsShow) subtypes.push("live-reporter-insight");
  if (liveTickerUpdatesShow) subtypes.push("live-update");

  // NOTE: Number.isNaN behaves differently than global isNaN()
  // eslint-disable-next-line no-restricted-globals
  let numToShow = !isNaN(liveTickerNum)
    ? Number.parseInt(liveTickerNum, 10)
    : -1;

  const numToShowCarousel = NUM_CAROUSEL;

  const maxNumToShow = Math.max(numToShow, numToShowCarousel);

  items = items.filter((item) => subtypes.includes(item.subtype));

  // RETURN undefined: No live ticker due to be shown.
  if (
    items.length === 0 ||
    subtype !== "live-all" ||
    liveTickerHide ||
    numToShow <= 0
  ) {
    return undefined;
  }

  // NOTE: Seems dumb that the feature is required to show btw MIN (if that many are available)
  // and MAX items items. And yet, that's what it does! Unless it's a carousel.
  if (numToShow > MAX) numToShow = MAX;
  if (numToShow < MIN) numToShow = MIN;

  if (items.length > maxNumToShow) items = items.slice(0, maxNumToShow);

  const {
    liveTickerLabel = "",
    liveTickerLabelType = "kicker",
    liveTickerLabelUrl,
    liveTickerLabelLinkRemove = false,
    liveTickerLabelNameSpace = "liveTicker"
  } = overrides;

  // NOTE: When label object changes, this should change, too.
  // NOTE: The toolbar for this label is supppressed in Label.toolbar
  const compoundLabel = !liveTickerLabel
    ? undefined
    : getCompoundLabel({
        content: liveTickerData || content,
        overrides: {
          labelShow: true,
          labelType: liveTickerLabelType,
          label: liveTickerLabel,
          labelAlignment: "left", // not textAlignment b/c overall ticker ignores textAlignment
          labelUrl: liveTickerLabelUrl,
          labelLinkRemove: liveTickerLabelLinkRemove,
          labelNameSpace: liveTickerLabelNameSpace
        },
        isAdmin
      });

  return {
    url,
    subtypes,
    numToShow,
    numToShowCarousel,
    showTimestamps: !!liveTickerTimestampShow,
    isLive,
    pollFrequency,
    items,
    compoundLabel: { ...compoundLabel }
  };
};

export const transformFromAnsToFetched = (item) => {
  return {
    canonical_url: item.canonical_url,
    cms_modified: item.display_date,
    transformed_content: {
      headlines: {
        basic: item.headlines.basic
      }
    }
  };
};

/*
 * Creates the isSameContent function suitable suitable for passing to the
 * usePolledData hook.
 *
 * @param {int} numToShow -- The number of items to show.
 *
 * @return {function} -- The is isSameContent function which is what
 * what determines if the data fetched via usePolledData is fresh or
 * the same as the previous fetch
 */
export const makeIsSameContent = (numToShow) => (a, b) => {
  // NOTE: if there is no new content, i.e. b.content.children, don't update
  // by returning true.
  if (!b?.content?.children) return true;

  const oldContent = get(a, "content.children", []).slice(0, numToShow);
  const newContent = get(b, "content.children", []).slice(0, numToShow);

  if (oldContent.length !== newContent.length) return false;

  return oldContent.reduce((acc, oldItem, i) => {
    const newItem = newContent[i];
    const headOld = get(oldItem, "transformed_content.headlines.basic");
    const headNew = get(newItem, "transformed_content.headlines.basic");

    return acc && headOld === headNew;
  }, true);
};

getLiveTicker.propTypes = {
  content: PropTypes.object,
  liveTickerData: PropTypes.object,
  overrides: PropTypes.object,
  isAdmin: PropTypes.bool
};

export const groomLiveTickerForJsonApp = ({ liveTicker }) => {
  if (!liveTicker) return undefined;

  delete liveTicker.items;

  // groom label for json app
  if (Object.keys(liveTicker.compoundLabel || {}).length === 0) {
    delete liveTicker.compoundLabel;
  } else {
    liveTicker.compoundLabel = groomCompoundLabelForJsonApp({
      compoundLabel: liveTicker.compoundLabel
    });
    liveTicker.label = liveTicker.compoundLabel;
    delete liveTicker.compoundLabel;
  }

  return liveTicker;
};

groomLiveTickerForJsonApp.propTypes = {
  liveTicker: PropTypes.object
};
