import PropTypes from "prop-types";

/* Helpers */
import {
  getCompoundLabel,
  groomCompoundLabelForJsonApp
} from "./Label.helpers";

export const getCallToAction = ({
  content = {},
  overrides = {},
  isAdmin = false
}) => {
  const {
    ctaLabelShow,
    ctaLabel,
    ctaLabelUrl,
    ctaLabelLinkRemove,
    ctaLabelSecondaryShow,
    ctaLabelSecondary,
    ctaLabelType,
    ctaLabelAlignment,
    ctaLabelIcon,
    ctaLabelNameSpace
  } = overrides;

  if (!ctaLabelShow) return undefined;

  // NOTE: When label object changes, this should change, too.
  const cta = getCompoundLabel({
    content,
    overrides: {
      ...overrides,
      labelShow: ctaLabelShow,
      labelType: ctaLabelType,
      label: ctaLabel,
      labelUrl: ctaLabelUrl,
      labelLinkRemove: ctaLabelLinkRemove,
      labelSecondaryShow: ctaLabelSecondaryShow,
      labelSecondary: ctaLabelSecondary,
      labelAlignment: ctaLabelAlignment,
      labelIcon: ctaLabelIcon,
      labelNameSpace: ctaLabelNameSpace
    },
    isAdmin
  });

  return {
    isAdmin,
    cta
  };
};

getCallToAction.propTypes = {
  content: PropTypes.object,
  overrides: PropTypes.object,
  isAdmin: PropTypes.bool
};

export const groomCallToActionForJsonApp = ({ cta }) =>
  groomCompoundLabelForJsonApp({ compoundLabel: cta });
