import { defaultMenuItemProps } from "../_utilities/helpers";

/**
 * Menu options
 * 1. Hide blurb: bool
 * 2. Number of extra blurbs to show: int
 */

const getBlurbToolbarItems = ({ numExtrasToShow }) => {
  const toolbar = [
    {
      type: "button",
      props: {
        iconName: "hide",
        label: "Hide blurb"
      },
      initialVals: { selected: false },
      handlers: {
        onClick: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            entity.props.customFields.blurbHide =
              !entity.props.customFields.blurbHide;
          });
        }
      }
    },
    {
      type: "numberSelect",
      props: {
        iconName: "addItems",
        label: "Additional Blurbs",
        items: Array(6)
          .fill("")
          .map((o, i) => String(i))
      },
      initialVals: {
        defaultValue: String(numExtrasToShow)
      },
      handlers: {
        onChange: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            entity.props.customFields.blurbNumExtrasToShow = Number(
              props.value
            );
          });
        }
      }
    }
  ];

  return toolbar;
};

export default getBlurbToolbarItems;
