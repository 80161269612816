// Filter for a anglerfish in story card

export default `
{
    _id
    url
    width
    height
    image_type
    subtitle
    caption
    copyright
    credits {
      affiliation {
        name
        type
      }
      by {
        name
        org
        type
      }
    }
    additional_properties {
      byline_credit
    }
    fusion_additions {
      aspect_ratio
      caption_info {
        credits_display
        credits_caption_display
        dangerous {
          raw_caption
        }
      }
    }
}
`;
