const fixed = [
  "Blurb",
  "Excerpt",
  "Sigline",
  "Recipe",
  "LiveTicker",
  "Audio",
  "RelatedLinks",
  "CallToAction"
];
const fixedBottom = ["Footnote"];
const layouts = {
  ART_ABOVE_HEAD: {
    layout: {
      sidebar: { items: ["Count"] },
      top: { items: ["TopperLabel", "Label", "Art", "Headline", ...fixed] },
      left: { items: [] },
      right: { items: [] },
      bottom: { items: [...fixedBottom] }
    }
  },
  ART_BELOW_HEAD: {
    layout: {
      sidebar: { items: ["Count"] },
      top: { items: ["TopperLabel", "Label", "Headline", "Art", ...fixed] },
      left: { items: [] },
      right: { items: [] },
      bottom: { items: [...fixedBottom] }
    }
  },
  ART_BELOW_SIGLINE: {
    layout: {
      sidebar: { items: ["Count"] },
      top: {
        items: [
          "TopperLabel",
          "Label",
          "Headline",
          ...fixed.slice(0, fixed.indexOf("Sigline") + 1),
          "Art",
          ...fixed.slice(fixed.indexOf("Sigline") + 1)
        ]
      },
      left: { items: [] },
      right: { items: [] },
      bottom: { items: [...fixedBottom] }
    }
  },
  LEFT: {
    layout: {
      sidebar: { items: ["Count"] },
      top: { items: ["TopperLabel"] },
      left: {
        items: ["Art"],
        isArtSlot: true
      },
      right: { items: ["Label", "Headline", ...fixed] },
      bottom: { items: [...fixedBottom] }
    }
  },
  LEFT_OF_HEAD: {
    layout: {
      sidebar: { items: ["Count"] },
      top: { items: ["TopperLabel", "Label"] },
      left: {
        items: ["Art"],
        isArtSlot: true
      },
      right: {
        items: ["Headline", ...fixed]
      },
      bottom: { items: [...fixedBottom] }
    }
  },
  LEFT_OF_BLURB: {
    layout: {
      sidebar: { items: ["Count"] },
      top: { items: ["TopperLabel", "Label", "Headline"] },
      left: {
        items: ["Art"],
        isArtSlot: true
      },
      right: {
        items: [...fixed]
      },
      bottom: { items: [...fixedBottom] }
    }
  },
  RIGHT: {
    layout: {
      sidebar: { items: ["Count"] },
      top: { items: ["TopperLabel"] },
      right: {
        items: ["Art"],
        isArtSlot: true
      },
      left: {
        items: ["Label", "Headline", ...fixed]
      },
      bottom: { items: [...fixedBottom] }
    }
  },
  RIGHT_OF_HEAD: {
    layout: {
      sidebar: { items: ["Count"] },
      top: { items: ["TopperLabel", "Label"] },
      right: {
        items: ["Art"],
        isArtSlot: true
      },
      left: {
        items: ["Headline", ...fixed]
      },
      bottom: { items: [...fixedBottom] }
    }
  },
  RIGHT_OF_BLURB: {
    layout: {
      sidebar: { items: ["Count"] },
      top: { items: ["TopperLabel", "Label", "Headline"] },
      right: {
        items: ["Art"],
        isArtSlot: true
      },
      left: {
        items: [...fixed]
      },
      bottom: { items: [...fixedBottom] }
    }
  }
};

const fetchLayoutFromArtPosition = (overrides = {}) => {
  const { artPosition } = overrides;

  switch (artPosition) {
    case "Art above head":
      return layouts.ART_ABOVE_HEAD;
    case "Art below head":
      return layouts.ART_BELOW_HEAD;
    case "Art below byline":
      return layouts.ART_BELOW_SIGLINE;
    case "Art left":
      return layouts.LEFT;
    case "Art left of head":
      return layouts.LEFT_OF_HEAD;
    case "Art left of blurb":
      return layouts.LEFT_OF_BLURB;
    case "Art right":
      return layouts.RIGHT;
    case "Art right of head":
      return layouts.RIGHT_OF_HEAD;
    case "Art right of blurb":
      return layouts.RIGHT_OF_BLURB;
    default:
      return layouts.ART_ABOVE_HEAD;
  }
};

export default fetchLayoutFromArtPosition;
