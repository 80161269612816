import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useRenderedContentContext } from "~/components/contexts/rendered-content-context";
import fetchLayoutFromArtPosition from "./_children/artPosition";
import { Markup, getJsonFromLayout } from "./_children/helpers";
import { allTransformsOrder } from "./_utilities/helpers";

const StoryCard = (props) => {
  const { overrides, className = "", style = {}, attrs = {} } = props;
  const { UpdateRenderedContentInChainCtx = Fragment } =
    useRenderedContentContext();

  let { layout } = fetchLayoutFromArtPosition(overrides);

  // Critical piece -- this modifies the layout so the components are ordered the way we want.
  layout = allTransformsOrder({ layout, overrides });

  const json = getJsonFromLayout({ layout, ...props });

  return (
    <Fragment>
      <UpdateRenderedContentInChainCtx {...props} />
      {Markup({ json, overrides, className, style, attrs })}
    </Fragment>
  );
};

StoryCard.propTypes = {
  overrides: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
  attrs: PropTypes.object
};

export default React.memo(StoryCard);
