import PropTypes from "prop-types";

/* Helpers */
import {
  getCompoundLabel,
  groomCompoundLabelForJsonApp
} from "./Label.helpers";

export const getTopperLabel = ({
  overrides = {},
  index = 0,
  isAdmin = false
}) => {
  const {
    topperLabelShow = false,
    topperLabelType = "Package",
    topperLabel = "",
    topperLabelLinkRemove = false,
    topperLabelUrl = "",
    topperLabelSecondaryShow = false,
    topperLabelSecondary = "",
    topperLabelAlignment = "inherit",
    topperLabelIcon = "None",
    topperLabelNameSpace = "topper"
  } = overrides;

  // NOTE: In feeds, the topper label acts as a feed label, hence only render
  // it on the first item, i.e. index === 0
  if (index !== 0 || !topperLabelShow) return undefined;

  // NOTE: When label object changes, this should change, too.
  const compoundLabel = getCompoundLabel({
    content: {},
    overrides: {
      labelShow: topperLabelShow,
      labelType: topperLabelType,
      label: topperLabel,
      labelLinkRemove: topperLabelLinkRemove,
      labelUrl: topperLabelUrl,
      labelSecondaryShow: topperLabelSecondaryShow,
      labelSecondary: topperLabelSecondary,
      labelAlignment: topperLabelAlignment,
      labelIcon: topperLabelIcon,
      labelNameSpace: topperLabelNameSpace
    },
    isAdmin
  });

  return compoundLabel;
};

getTopperLabel.propTypes = {
  overrides: PropTypes.object,
  isAdmin: PropTypes.bool
};

export const groomTopperLabelForJsonApp = ({ topperLabel }) => {
  if (!topperLabel || !Object.keys(topperLabel).length) {
    return undefined;
  }

  // groom label for json app
  topperLabel = groomCompoundLabelForJsonApp({
    compoundLabel: topperLabel
  });

  return topperLabel;
};

groomTopperLabelForJsonApp.propTypes = {
  relatedLinks: PropTypes.object
};
