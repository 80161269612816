import PropTypes from "prop-types";
import kebabcase from "lodash.kebabcase";

/* Helpers */
import {
  getCompoundLabel,
  groomCompoundLabelForJsonApp
} from "./Label.helpers";
import { isHTMLFragment } from "./CustomHTML.helpers";

/* Utilities */
import { fetchProps, getLoopControl } from "../_utilities/data";

const getPosition = (position) => {
  switch (position) {
    case "Bottom":
      return "bottom";
    case "None":
      return "none";
    default:
      return "below-sigline";
  }
};

const getStyle = (style) => {
  switch (style) {
    case "Side-by-side":
      return "side-by-side";
    default:
      return "stacked";
  }
};

const getSize = (size) => kebabcase(size);

const getPrependBullet = (arrangement, size) =>
  arrangement === "side-by-side" ||
  (/^related-links$/.test(size) && arrangement === "stacked");

const getFontWeight = (size, fontStyle, fontFamily) => {
  if (/^related-links$/.test(size)) return "regular";
  if (/^Ultra/.test(fontFamily)) return "bold";
  if (/^Bold/.test(fontStyle)) return "bold";
  return "regular";
};

const getFontFamily = (size, fontStyle, fontFamily) => {
  if (/^related-links$/.test(size)) return "subhead";
  if (/^Ultra/.test(fontFamily)) return "magazine";
  return "headline";
};

const getDelim = (delim, arrangement, prependBullet) => {
  if (arrangement === "side-by-side")
    delim = !prependBullet && !delim ? " " : delim;
  return delim;
};

export const getRelatedLinks = ({
  content = {},
  overrides = {},
  isAdmin = false
}) => {
  // NOTE: Goal is to produce this because it ~conforms to the current jsonApp structure
  /*
    {
      label: getCompoundLabel(),
      items: [
        {
          text: "string",
          url: "url",
          // NOTE: Not done yet
          type: "article|web|etc.",
          // NOTE: Only included if isAdmin=true
          editableContentInfo: {
            attrs: "object returned by editableContent()",
            placeholderValue: "string"
          }
        }
      ],
      info: {
        position: "string:below-sigline|bottom",
        arrangment: "string:stacked|side-by-side",
        size: "string:Related link|Small|Extra small|etc.",
      }
    }
  */

  const {
    textAlignment = "left",
    // NOTE: Headline options affect related links
    headlineStyle = "Bold",
    headlineFontFamily = "Default",
    relatedLinksHide = false,
    relatedLinksNum = 0,
    relatedLinksOrder = "",
    relatedLinksPosition = "Default",
    relatedLinksStyle = "normal",
    relatedLinksSize = "Related links",
    relatedLinksDelim,
    relatedLinksLabel = "",
    relatedLinksLabelType = "Kicker",
    relatedLinksLabelUrl = "",
    relatedLinksLabelLinkRemove = true,
    relatedLinksLabelNameSpace = "relatedLinks"
  } = overrides;

  const okToProceed =
    !relatedLinksHide && relatedLinksPosition !== "None" && relatedLinksNum > 0;

  if (!okToProceed) return undefined;

  // NOTE: Assemble list of related link items
  const items = [];

  // NOTE: Harvest related links
  const loopControl = getLoopControl({
    n: relatedLinksNum,
    order: relatedLinksOrder.split(",")
  });

  loopControl.forEach((v) => {
    const textKey = `feature_relatedLinks_${v}_text`;
    const linkKey = `feature_relatedLinks_${v}_link`;
    const textPlaceholder = `Related link ${v}`;
    const item = {};

    const harvestedData = fetchProps({
      data: content,
      keys: {
        text: [textKey, textKey],
        link: [linkKey, linkKey]
      },
      overrides
    });

    item.textKey = textKey;
    item.linkKey = linkKey;

    if (
      isAdmin ||
      (harvestedData.text && harvestedData.text !== textPlaceholder)
    ) {
      item.text = harvestedData.text || textPlaceholder;
      item.url = harvestedData.link || undefined;
      if (!isAdmin && harvestedData.link === "Enter url") {
        item.url = undefined;
      } else if (isAdmin && !harvestedData.link) {
        item.url = "Enter url";
      }
      items.push(item);
    }
  });

  // NOTE: When label object changes, this should change, too.
  // NOTE: The toolbar for this label is supppressed in Label.toolbar
  const compoundLabel = !relatedLinksLabel
    ? undefined
    : getCompoundLabel({
        content,
        overrides: {
          labelShow: true,
          labelType: relatedLinksLabelType,
          label: relatedLinksLabel,
          labelAlignment: textAlignment,
          labelUrl: relatedLinksLabelUrl,
          labelLinkRemove: relatedLinksLabelLinkRemove,
          labelNameSpace: relatedLinksLabelNameSpace
        },
        isAdmin
      });

  if (!items || !items.length) return undefined;

  const position = getPosition(relatedLinksPosition);
  const arrangement = getStyle(relatedLinksStyle);
  const size = getSize(relatedLinksSize);
  const prependBullet = getPrependBullet(arrangement, size);
  const delim = getDelim(relatedLinksDelim, arrangement, prependBullet);
  const fontWeight = getFontWeight(size, headlineStyle, headlineFontFamily);
  const fontFamily = getFontFamily(size, headlineStyle, headlineFontFamily);

  return {
    compoundLabel: { ...compoundLabel },
    items: items.length > 0 ? items : undefined,
    info:
      items.length > 0
        ? {
            position,
            arrangement,
            size,
            prependBullet,
            delim,
            fontWeight,
            fontFamily
          }
        : undefined,
    relatedLinksOrder
  };
};

getRelatedLinks.propTypes = {
  content: PropTypes.object,
  overrides: PropTypes.object,
  isAdmin: PropTypes.bool
};

export const groomRelatedLinksForJsonApp = ({ relatedLinks }) => {
  if (!relatedLinks) {
    return undefined;
  }

  (relatedLinks.items || []).forEach((item) => {
    delete item.textKey;
    delete item.linkKey;
    if (isHTMLFragment(item.text)) {
      item.mime = "text/html";
    }
  });

  // groom size
  if (relatedLinks?.info?.size) {
    if (/^related-links$/.test(relatedLinks.info.size)) {
      // NOTE: default size not needed for jsonapp
      delete relatedLinks.info.size;
    } else if (relatedLinks.info.size.includes("extra")) {
      // convert extra-large and extra-small to x-large and x-small
      relatedLinks.info.size = relatedLinks.info.size.replace("extra", "x");
    }
  }

  // START: groom info
  if (relatedLinks?.info?.fontWeight)
    relatedLinks.info.fontStyle = relatedLinks.info.fontWeight;
  if (/magazine/.test(relatedLinks?.info?.fontFamily))
    relatedLinks.info.style = "style";
  if (relatedLinks?.info?.prependBullet)
    relatedLinks.info.delimiter = "diamond";
  // NOTE: delete what's not needed for the apps
  delete relatedLinks?.info?.prependBullet;
  delete relatedLinks?.info?.delim;
  delete relatedLinks?.info?.fontWeight;
  delete relatedLinks?.info?.fontFamily;
  // END: groom info

  // groom label for json app
  if (Object.keys(relatedLinks.compoundLabel || {}).length === 0) {
    delete relatedLinks.compoundLabel;
  } else {
    relatedLinks.compoundLabel = groomCompoundLabelForJsonApp({
      compoundLabel: relatedLinks.compoundLabel
    });
    relatedLinks.label = relatedLinks.compoundLabel;
    delete relatedLinks.compoundLabel;
  }

  return relatedLinks;
};

groomRelatedLinksForJsonApp.propTypes = {
  relatedLinks: PropTypes.object
};
