import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

const FeatureLayoutContext = createContext({});

export function FeatureLayoutProvider({ value, children }) {
  return (
    <FeatureLayoutContext.Provider value={value}>
      {children}
    </FeatureLayoutContext.Provider>
  );
}

FeatureLayoutProvider.propTypes = {
  value: PropTypes.object,
  children: PropTypes.node
};

export function useFeatureLayoutContext() {
  return useContext(FeatureLayoutContext);
}
