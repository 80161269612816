import PropTypes from "prop-types";
import { fetchProps } from "../_utilities/data";

export const getExcerpt = ({ content, overrides = {}, isAdmin }) => {
  const { excerptHide = true } = overrides;

  if (excerptHide) return undefined;

  let { text } = fetchProps({
    data: content,
    keys: {
      text: ["fusion_additions.excerpt", "excerptText"]
    },
    overrides
  });

  const placeholderText = "Write excerpt here";
  if (isAdmin) {
    text = text || placeholderText;
  } else if (!isAdmin && text === placeholderText) {
    text = "";
  }

  if (!text) return undefined;

  return {
    isAdmin,
    text
  };
};

getExcerpt.propTypes = {
  content: PropTypes.object,
  overrides: PropTypes.object,
  isAdmin: PropTypes.bool
};
