/**
 * Takes a string and makes it a slug.
 *
 * @param {string} str - the string to be slugified
 * @returns {string} string like 'world' or 'world-of-dogs' or 'world-and-dogs'
 */
export const slugify = (str) => {
  if (!str) return ""; // Defensive check
  return str
    .replace(/[,.]/g, "")
    .replace(/&/g, "and")
    .replace(/\//g, "")
    .replace(/\s/g, "-")
    .replace(/[']/g, "")
    .toLowerCase();
};

/**
 * Capitalizes the first letter of a string
 *
 * @param {string} str - the string to be capitalized
 */
export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Truncates a string at a specific character index and an adds ellipsis
 *
 * @param {string} str - the string to be truncated
 * @param {number} index - the index to end at
 */
export const truncateString = (str, index) => {
  return `${str.substring(0, index)}...`;
};

/**
 * Rounds number to the nearest hundred and adds a "k" if more than 999
 *
 * @param {number} num - number to be formatted
 */
export const kFormat = (num) => {
  if (num > 999) {
    return `${Math.ceil(num / 100) / 10}k`;
  }
  return num;
};
